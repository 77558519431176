
import React, { useState, useEffect, useContext, Props } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CreateUser, LastSignIn, LastSignInsPerUser, ListContainer, Package, RowUser, User } from "../../models";
import {
  assignManagerToUser,
  createUser,
  getManagerId,
  getSignIns,
  getUserInfo,
  getUserList,
  getUserListRaw,
  updateUser,
} from "../../services/userService";
import { RootState } from "../../store/RootReducer";
import {
  PackageSelectorContainer,
  UserNotFoundPopup,
} from ".";
import { UserDetailContainer } from "./UserDetailContainer";
import { EventContext } from "../../utils/SignalR/NotificationState";
import { CircularProgress } from "@material-ui/core";
import { GenericLoader } from "../../components/GenericLoader";
import { LastSignInContainer } from "./LastSignInContainer";


const Loading = () => {
  return (

    <Grid item container justify="center" alignContent="center" alignItems="center" >
      <CircularProgress color="primary"></CircularProgress>
    </Grid>
  );
};

interface MatchParams {
  userId: string;
}

export const UserManagement = (): React.ReactElement => { // eslint-disable-line sonarjs/cognitive-complexity
  const { t } = useTranslation(['user', 'common']);
  const { userId } = useParams();
  const { handleSubmit, setError, reset, errors, register } = useForm<CreateUser>();
  const navigate = useNavigate();
  const userState = useSelector((state: RootState) => state.userState);
  const [isUserEdit, setIsUserEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [hasChangedForm, setHasChangedForm] = useState(false);
  const [openUserNotFound, setOpenUserNotFound] = useState(false);
  const [packagesToDelete, setPackagesToDelete] = useState<Package[]>([]);
  const [packagesToAdd, setPackagesToAdd] = useState<Package[]>([]);
  const { connectionId } = useContext(EventContext);
  const [saving, setSaving] = useState(false);
  const [inError, setInError] = useState(false);
  const [lastSignInForUser, setLastSignIn] = useState<TableProps>();
  const [lastSignInsPerUser, setLastSignInsPerUser] = useState<LastSignInsPerUser[]>();
  const [managers, setManagers] = useState<{ id: string; displayName: string }[]>([]);
  const [managerId, setManagerId] = useState<string | undefined>(undefined);

  useEffect(() => {
    setIsUserEdit(userId !== undefined);
    getUserListRaw()
      .then ((data: ListContainer<RowUser>) => {
        const managerOptions = data.items.map((user) => ({
          id: user.id,
          displayName: user.displayName,
        }));
        setManagers(managerOptions);
      }).then (() => {
    
    if (userId !== undefined) {
      setIsLoading(true);
      let allSignIns: any;
      getSignIns()
        .then((lastSignIns: LastSignInsPerUser[]) => {
          allSignIns = lastSignIns;
          setLastSignInsPerUser(lastSignIns);
        })
        .then(() => {
          getUserInfo(userId)
            .then((user: User) => {
              reset(user);
              setEditingUser(user);
              const lastSignInUser = getLastSignInsForUser(user.email, allSignIns, user.otherMails);
              const before: TableProps = { rows: lastSignInUser };
              setLastSignIn(before);
              getManagerId(userId).then((managerId: string) => {
                setManagerId(managerId); // Met à jour le state avec l'ID du manager
              });
            })
            .catch(() => {
              setOpenUserNotFound(true);
            })
            .finally(() => setIsLoading(false));
        });
    }});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, reset,]);

  const onSave = async (data: CreateUser) => {
    setInError(false);
    setSaving(true);

    if (!isUserEdit) {
      await saveNewUser(data);
    } else {
      updateEditingUser(data)
      if(userId !== undefined && managerId !== undefined)
      assignManagerToUser(userId, managerId);
     
    }
  };

  interface TableProps {
    rows: LastSignInsPerUser | undefined;
  }

  const getLastSignInsForUser = (userEmail: string, allSignIns: LastSignInsPerUser[], otherEmails: string[] | null) => {
    return allSignIns?.find(lastSignInPerUser => lastSignInPerUser.userPrincipalName?.toLowerCase() === userEmail?.toLowerCase() || otherEmails?.some(x => x.toLowerCase() == lastSignInPerUser.userPrincipalName?.toLowerCase()));
  }

  const chunkString = (str: string): string[] => {
    const length = Math.floor(str.length / 3);
    const chunkedString = str.match(new RegExp('.{1,' + length + '}', 'g'));

    if (chunkedString) {
      return chunkedString;
    }

    throw new Error('String could not be chunked');
  }



  const saveNewUser = async (data: CreateUser) => {

    data.companyName = userState.loggedInUser.companyName;
    data.createdRedirectUrl = chunkString(window.runConfig.newUserRedirectUrl);

    data.administrativeUnitName = userState.loggedInUser.administrativeUnits[0];
    data.accessPackages = packagesToAdd.map(x => ({ accessPackageId: x.id, accessPackagePolicyId: x.assignmentPolicyId }));
    if(managerId !== undefined){
      console.log("not underfined", managerId);
      data.managerId = managerId;
    }
  
  
    createUser(data, connectionId).then((res) => {
      setSaving(false);
      navigate("/");
    }
    )
      .catch((error) => {
        setSaving(false);
        // We should provide generic error handling in the future as well.
        if (error.response.data === 'get-user-by-email-exists') {
          setError("email", {
            type: "manual",
            message: t(`common:errors.${error.response.data}`, { email: data.email }),
          });
        }
      });
  };

  const updateEditingUser = (data: CreateUser) => {
    if (editingUser) {
      const user = { ...editingUser };
      user.lastName = data.lastName;
      user.firstName = data.firstName;

      updateUser(user, packagesToAdd, packagesToDelete, connectionId);
      setSaving(false);
      navigate("/");
    }
  };

  const closePopup = (goBack: boolean): void => {
    setOpenUserNotFound(false);
    if (goBack) {
      navigate("/");
    }
  };

  return (
    <Grid container direction="row" justify="center" className="p-t-16">
      <Grid
        item
        container
        direction="column"
        justify="flex-start"
        spacing={2}
        xs={12}
        md={10}
        xl={8}
      >
        <Grid item>
          <h1>{t("title")}</h1>
        </Grid>
        <GenericLoader loading={isLoading} >
          <form autoComplete="off" onSubmit={handleSubmit((d: CreateUser) => onSave(d))}>
            <Grid item container direction="row">
              <UserDetailContainer
                changedForm={() => setHasChangedForm(true)}
                isUserEdit={isUserEdit}
                saving={saving}
                register={register}
                errors={errors}
                managers={managers}
                managerId={managerId}
                onManagerChange={(newManagerId: string) => setManagerId(newManagerId)}
              />
              <PackageSelectorContainer
                inError={inError}
                editingUser={editingUser}
                isUserEdit={isUserEdit}
                saving={saving}
                hasChangedForm={hasChangedForm}
                setPackagesToDelete={setPackagesToDelete}
                setPackagesToAdd={setPackagesToAdd}
              />
              {lastSignInForUser?.rows != undefined ?
                <Grid item container direction="row">
                  <Grid item container direction="row">
                    <h3>Last sign-ins per application</h3>
                  </Grid>

                  <LastSignInContainer rows={lastSignInForUser?.rows}
                  />
                </Grid>
                : <h3>No login data for this user</h3>}

            </Grid>
          </form>
        </GenericLoader>
      </Grid>
      <UserNotFoundPopup open={openUserNotFound} onClose={closePopup} />
    </Grid>
  );
};
