import { axiosInstance } from "./axiosInstance";
import { AxiosResponse } from 'axios';
import {
  CreatedUser,
  CreateUser,
  ListContainer,
  User,
  RowUser,
  Package,
  UserPackage,
  UserCount,
  PackageAssignedUser,
  LastSignInsPerUser
} from "../models";
import { stringify } from "querystring";

export const getUserInfo = (userId: string): Promise<User> => {
  return axiosInstance
    .get<User>(`user/info/${userId}`)
    .then((response: AxiosResponse<User>) => response.data);
};

export const createUser = (user: CreateUser, connectionId: string): Promise<AxiosResponse<CreatedUser> | string | null> => {
  return axiosInstance
    .post<CreatedUser>(`/user/guest?connectionId=${connectionId}`, {
      firstName: user.firstName,
      lastName: user.lastName,
      companyName: user.companyName,
      email: user.email,
      createdRedirectUrl: user.createdRedirectUrl,
      personalMessage: user.personalMessage,
      administrativeUnitName: user.administrativeUnitName,
      accessPackages: user.accessPackages,
      managerId : user.managerId
    });
};

export const updateUser = (user: User, addList: Package[], deleteList: Package[], connectionId: string): Promise<boolean | string> => {
  return axiosInstance
    .put(`/User?connectionId=${connectionId}`, {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      companyName: user.companyName,
      email: user.email,
      packagesToAdd: addList.map(x => ({ accessPackageId: x.id, accessPackagePolicyId: x.assignmentPolicyId })),
      packagesToDelete: deleteList.map(x => ({ accessPackageId: x.id, accessPackagePolicyId: x.assignmentPolicyId }))
    })
    .then((response: AxiosResponse<boolean | string>) => {
      const data = response.data;
      if (typeof data === "string" && data.length > 0) {
        return response.data;
      }

      return true;
    })
    .catch(() => false);
};

export const getSignIns = (): Promise<LastSignInsPerUser[]> => {
  return axiosInstance
    .get<LastSignInsPerUser[]>(
      `/SignIns`
    )
    .then((response: AxiosResponse<LastSignInsPerUser[]>) => response.data);
};


export const getUserList = (
  searchTerm: string,
  order: string,
  orderBy: string,
  rowsPerPage: number,
  page: number,
  administrativeUnitName: string
): Promise<ListContainer<RowUser>> => {
  return axiosInstance
    .get<ListContainer<RowUser>>(
      `/AdministrativeUnit/?searchTerm=${searchTerm}&order=${order}&orderBy=${orderBy}&rowsPerPage=${rowsPerPage}&page=${page}`
    )
    .then((response: AxiosResponse<ListContainer<RowUser>>) => response.data);
};

export const getDirectReportsByUserId = (userId: string) : Promise<RowUser[]> => {
  return axiosInstance
      .get<RowUser[]>(
        `/user/${userId}/directReports`
      ).then((response: AxiosResponse<RowUser[]>) => response.data);
}

export const getUserListRaw = () : Promise<ListContainer<RowUser>> => {
  return axiosInstance
    .get<ListContainer<RowUser>>(
      `/AdministrativeUnit/`
    )
    .then((response: AxiosResponse<ListContainer<RowUser>>) => response.data);
};

export const userExists = (email: string): Promise<boolean> => {
  return axiosInstance
    .get<boolean>(`user/exists/${encodeURI(email)}`)
    .then((response: AxiosResponse<boolean>) => response.data)
    .catch(() => false);
};

export const getManagerId = (userId: string): Promise<string> => {
  return axiosInstance
      .get<string>(`/user/${userId}/manager`)
      .then((response: AxiosResponse<string>) => response.data)
      .catch(() => "");
}

export const assignManagerToUser = (userId: string, managerId: string): Promise<boolean> => {
  return axiosInstance
      .post<boolean>(`/user/${userId}/manager/${managerId}`)
      .then((response: AxiosResponse<boolean>) => response.data)
      .catch(() => false);
}

export const deleteUser = (userId: string, connectionId: string): Promise<null> => {
  return axiosInstance.delete(`/user/${userId}?connectionId=${connectionId}`);
};

export const getPackageList = (adminUnitName: string): Promise<Package[]> => {
  return axiosInstance
    .get<Package[]>(`/governance/adminunit/packages`)
    .then((response: AxiosResponse<Package[]>) => response.data)
    .catch(() => []);
};

export const addPackages = (list: Package[], userId: string): Promise<AxiosResponse<UserPackage[]> | null> => {
  return axiosInstance
    .post<UserPackage[]>(`/Governance/package/assignment`, { userId, accessPackages: list.map(x => ({ accessPackageId: x.id, accessPackagePolicyId: x.assignmentPolicyId })) })
    .then((response: AxiosResponse<UserPackage[]>) => {
      return response
    })
    .catch(() => {
      return null // return null
    });
}

export const deletePackages = (accessPackageId: string, userId: string): Promise<void> => {
  return axiosInstance
    .delete(`/Governance/package/assignment`, { params: { userId, accessPackageId } });
}

export const getUserCountForPackage = (accessPackageId: string): Promise<number | null> => {
  return axiosInstance
    .get<UserCount>(`/Governance/package/${accessPackageId}/users/count`)
    .then((response: AxiosResponse<UserCount>) => response.data.userCount)
    .catch(() => null);
}

export const getUsersForPackage = (accessPackageId: string): Promise<PackageAssignedUser[]> => {
  return axiosInstance
    .get(`/Governance/package/${accessPackageId}/users`)
    .then((response: AxiosResponse<PackageAssignedUser[]>) => response.data)
    .catch(() => []);
}