import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { AtriasTheme, PageLoader } from "@atrias/react-atrias-components";
import "@atrias/react-atrias-components/dist/index.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initI18n } from "./i18n";
import { Config } from "./utils/CustomWindow";
import { msalConfig } from "./services/msal-config";
import store from "./store";

declare global {
  interface Window {
    runConfig: Config;
  }
}

initI18n();

const msalInstance = new PublicClientApplication(msalConfig);

const PageLoaderContainer = () => (
  <div style={{ height: "100vh" }}>
    <PageLoader />
  </div>
);

const rootEle = document.getElementById("root");
if(rootEle){
  createRoot(rootEle).render(
    <Provider store={store}>
      <Suspense fallback={<PageLoaderContainer />}>
        <MsalProvider instance={msalInstance}>
          <ThemeProvider theme={AtriasTheme}>
            <StylesProvider injectFirst>
              <App />
            </StylesProvider>
          </ThemeProvider>
        </MsalProvider>
      </Suspense>
    </Provider>
  );
}


serviceWorker.unregister();
