import React from "react";
import { makeStyles, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { RequiredInputField } from "../../components/RequiredInputField";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";

interface Props {
  saving: boolean;
  changedForm: () => void;
  isUserEdit: boolean;
  register: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  errors: any; //eslint-disable-line @typescript-eslint/no-explicit-any
  managers: { id: string; displayName: string }[];
  managerId?: string;
  onManagerChange: (id: string) => void; // Ajouter onManagerChange
}

const useStyles = makeStyles(() => ({
  helper__root: {
    margin: "4px 0 0 8px",
    fontWeight: "normal",
    fontStyle: "italic",
    color: "#979797",
    fontSize: "0.8rem",
  },
  hide: {
    display: "none",
  },
}));

export const UserDetailContainer = (props: Props): React.ReactElement => {
  const { saving, changedForm, isUserEdit, register, errors, managers, managerId, onManagerChange } = props;
  const { t } = useTranslation('user');

  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="column"
      xs={12}
      md={6}
      xl={4}
      className="p-8"
      spacing={2}
    >
      <Grid item>
        <h3>{t("sub-title.profile")}</h3>
      </Grid>
      <Grid item>
        <RequiredInputField
          name="firstName"
          required
          errors={errors}
          register={register}
          disabled={saving}
          onChange={changedForm}
        />
      </Grid>
      <Grid item>
        <RequiredInputField
          name="lastName"
          required
          errors={errors}
          register={register}
          disabled={saving}
          onChange={changedForm}
        />
      </Grid>
      <Grid item>
        <RequiredInputField
          name="email"
          required
          errors={errors}
          register={register}
          disabled={saving || isUserEdit}
          onChange={changedForm}
        />
      </Grid>
      <Grid item>
        <TextField
          label={t("Manager")}
          select
          fullWidth
          {...register("managerId")}
          name="managerId"
          disabled={saving}
          value={managerId || ""}
          variant="outlined"
          onChange={(e) => {
            changedForm();
            props.onManagerChange(e.target.value);
          }}
        >
          {managers.map((manager) => (
            <MenuItem key={manager.id} value={manager.id}>
              {manager.displayName}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <TextField
        label={t("personalMessage.label")}
        inputRef={register()}
        name="personalMessage"
        multiline
        disabled={saving}
        rows={4}
        variant="outlined"
        className={classNames("m-8", { [classes.hide]: isUserEdit })}
        FormHelperTextProps={{
          classes: {
            root: classes.helper__root,
          },
        }}
      />
    </Grid>
  );
};
