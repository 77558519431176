import axios from 'axios';
import MsalUtils from '../utils/MsalUtilsConfigurator';
import { loginRequest } from './msal-config';
import { useMsal } from "@azure/msal-react";


const axiosInstance = axios.create({
  baseURL: window.runConfig.backendUrl,
  //baseURL: 'https://localhost:44392',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  async function (request) {
    const accessToken =
      await MsalUtils
        .getAccessToken(loginRequest.scopes)
        .then((token) => token)
        .finally();

    if (accessToken === null) {
      const { instance } = useMsal();
      instance.loginRedirect(loginRequest);
    }

    if (!request.headers?.Authorization) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    console.log(request.headers);

    return request;
  }
);

export { axiosInstance };
