import { useContext, useEffect, useState } from 'react'

import { EventContext } from './NotificationState';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import MsalUtils from '../../utils/MsalUtilsConfigurator';
import { loginRequest } from '../../services/msal-config';

export const SignalRConfigurator = (): null => {
  const { setConnectionId, setIsConnected, addNewEvent, updateEvent, addOrUpdateEvent } = useContext(EventContext);
  const [connection, setConnection] = useState<HubConnection>();

  useEffect(() => {
    const conn = new HubConnectionBuilder()
      .withUrl(`${window.runConfig.backendUrl}/notifications`, {
        accessTokenFactory: async () => {
          const token = await MsalUtils.getAccessToken(loginRequest.scopes);
          return token ?? "";
        }
      })
      .withAutomaticReconnect()
      .build();

    setConnection(conn);
  }, []);

  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      connection.start()
        .then(() => {
          if (connection.connectionId) {
            setConnectionId(connection.connectionId);
            setIsConnected(true);
          }

          connection.on("waitingCreateUserNotification", message => {
            const event = JSON.parse(message);
            addOrUpdateEvent(event);
          });

          connection.on("createUserNotification", message => {
            const event = JSON.parse(message);
            addOrUpdateEvent(event);
          });

          connection.on("updateUserNotification", message => {
            const event = JSON.parse(message);
            addOrUpdateEvent(event);
          });

          connection.onreconnected((connectionId) => {
            if (connectionId) {
              setConnectionId(connectionId);
            }
          })
        })
        .catch(e => {
          console.log('Connection failed: ', e)
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  return null;
}
